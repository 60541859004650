<template>
    <div class="container">
        blog
    </div>
</template>

<script>
export default {
    name: "blog",
    data() {
        return {}
    },
    methods: {}
}
</script>

<style lang="less" scoped>
</style>
